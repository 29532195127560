import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./containers/Home";
import Contact from "./containers/Contact";
import About from "./containers/About";
import About2 from "./containers/About2";
import Hannah from "./containers/Hannah";
import Exhibition from "./containers/ExhibitionOnlyInfo";
import ExhibitionStory from "./containers/ExhibitionStory";
import Stories from "./containers/Stories";
import Videos from "./containers/Videos";
import BehindTheScenes from "./containers/BehindTheScenes";

import Story01 from "./containers/Story-01";
import Story02 from "./containers/Story-02";
import Story03 from "./containers/Story-03";
import Story04 from "./containers/Story-04";
import Story05 from "./containers/Story-05";
import Story06 from "./containers/Story-06";
import Story07 from "./containers/Story-07";

import Video01 from "./containers/Video-01";
import Video02 from "./containers/Video-02";
import Video03 from "./containers/Video-03"



const Main = () => {
  return (
    <Switch>
      {/* <Route exact path="/but-god" component={About} />
      <Route exact path="/test" component={About2} />
      <Route exact path="/hannah" component={Hannah} /> */}
      <Route exact path="/exhibition" component={ExhibitionStory} />
      <Route exact path="/stories" component={Stories} />
      <Route exact path="/videos" component={Videos} />

      {/* <Route exact path="/story-01" component={Story01} />
      <Route exact path="/story-02" component={Story02} />
      <Route exact path="/story-03" component={Story03} />
      <Route exact path="/story-04" component={Story04} />
       */}

      <Route exact path="/stories/holly" component={Story01} />
      <Route exact path="/stories/sarah" component={Story02} />
      <Route exact path="/stories/mia-and-joren" component={Story03} />
      <Route exact path="/stories/joanne" component={Story05} />
      <Route exact path="/stories/alyssa" component={Story06} />
      <Route exact path="/stories/sharon" component={Story07} />
      <Route exact path="/stories/baylor" component={Story04} />
      <Route exact path="/videos/kathryn" component={Video01} />
      <Route exact path="/videos/courtney" component={Video02} />
      <Route exact path="/videos/cindy" component={Video03} />
      <Route exact path="/about" component={About} />
      <Route exact path="/hannah-corwin" component={Hannah} />

      <Route exact path="/behind-the-scenes" component={BehindTheScenes} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/home" component={Home} />
      <Route path="/" component={Home} />
    </Switch>
  )
}

export default Main;